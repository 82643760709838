<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Order List</h5>
				<DataTable :value="customer1" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
							v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
							:globalFilterFields="['name','country.name','representative.name','balance','status']" >
					
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        No customers found.
                    </template>
                    <template #loading>
                        Loading customers data. Please wait.
                    </template>
                    <Column field="order_id" header="Order Id" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.order_id}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by id"/>
                        </template>
                    </Column>
                    <Column header="Deliver To" field="deliver_to" style="min-width:14rem">
                        <template #body="{data}">
                            {{ data.deliver_to }}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by id"/>
                        </template>
                    </Column>
                    <Column header="Delivery Date" filterField="delivery_date" dataType="date" style="min-width:10rem">
                        <template #body="{data}">
							<span :class="'customer-badge status-' + data.importance">{{ data.delivery_date.split(' ')[0] }}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                        </template>
                    </Column>
					<Column header="Assigned To" field="assigned_to" style="min-width:14rem">
                        <template #body="{data}">
							<Dropdown id="assignto" @change="changeAssigned(data.order_id,data.assigned_to)" v-model="data.assigned_to" :options="users" optionLabel="name" optionValue="id" placeholder="Optional" :disabled="data.assigned_to===$store.state.user.id" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by id"/>
                        </template>
                    </Column>
                    <Column header="Status" field="status" style="min-width:10rem">
                        <template #body="{data}">
							<Button :label="textStatus(data.status)" class="p-2 px-3 p-button-text" :disabled="data.status==='2'" @click="changeStatus($event,data.order_id,data.status)"></Button>
                        </template>
                    </Column>
                    <Column header="Details" style="min-width:36rem">
                        <template #body="{data}">
                            <table>
								<tr v-for="item in data.items" :key="item.id">
									<td width="30%">{{ item.item_name }}</td>
									<td width="60%">{{ item.item_note }}</td>
									<td width="10%">{{ item.item_qty }}</td>
								</tr>
							</table>
                        </template>
                    </Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
	export default {
		data() {
			return {
				customer1: null,
				customer2: null,
				customer3: null,
				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
				idFrozen: false,
				products: null,
				users: [],
				expandedRows: [],
				statuses: [
					'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
				],
				representatives: [
					{name: "Amy Elsner", image: 'amyelsner.png'},
					{name: "Anna Fali", image: 'annafali.png'},
					{name: "Asiya Javayant", image: 'asiyajavayant.png'},
					{name: "Bernardo Dominic", image: 'bernardodominic.png'},
					{name: "Elwin Sharvill", image: 'elwinsharvill.png'},
					{name: "Ioni Bowcher", image: 'ionibowcher.png'},
					{name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
					{name: "Onyama Limba", image: 'onyamalimba.png'},
					{name: "Stephen Shaw", image: 'stephenshaw.png'},
					{name: "XuXue Feng", image: 'xuxuefeng.png'}
				],
			}
		},
		customerService: null,
		productService: null,
		created() {
			let that = this
			this.axios.post('/orders', {
				authToken: this.$store.state.sessionUnique
			}).then(function (response) {
				that.customer1 = response.data.orders
				that.loading1 = false
				that.users = response.data.users
			});
			this.initFilters1();
		},
		mounted() {
			// this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
			// this.customerService.getCustomersLarge().then(data => {
			// 	this.customer1 = data; 
			// 	this.loading1 = false;
			// 	this.customer1.forEach(customer => customer.date = new Date(customer.date));
			// });
			// this.customerService.getCustomersLarge().then(data => this.customer2 = data);
			// this.customerService.getCustomersMedium().then(data => this.customer3 = data);
			// this.loading2 = false;
		},
		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'representative': {value: null, matchMode: FilterMatchMode.IN},
					'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
					'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
					'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
				}
			},
			clearFilter1() {
				this.initFilters1();
			},
			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
			},
			collapseAll() {
				this.expandedRows = null;
			},
			textStatus(status) {
				let txt = ''
				switch (status) {
					case '0':
						txt = 'New'
						break;
					case '1':
						txt = 'Started'
						break;
					case '2':
						txt = 'completed'
						break;
				
					default:
						break;
				}
				console.log(status, txt)
				return txt
			},
			changeStatus(event, id, currentStatus) {
				let that = this
				if(parseInt(currentStatus) < 2) {
					this.$confirm.require({
						target: event.currentTarget,
						message: 'Change status to '+this.textStatus((parseInt(currentStatus)+1)+'')+'?',
						icon: 'pi pi-exclamation-triangle',
						accept: () => {
							this.axios.patch('/orders/status', {
								status: parseInt(currentStatus) + 1,
								id: id,
								authToken: this.$store.state.sessionUnique
							// eslint-disable-next-line no-unused-vars
							}).then(function (response) {
								that.$toast.add({severity:'success', summary:'Confirmed', detail:'Order status updated successfully.', life: 3000, function () {
									that.customer1 = response.data.orders
									that.loading1 = false
								}});
							});
						},
						reject: () => {}
					})
				}
				
			},
			changeAssigned(orderid, userid) {
				let that = this
				this.axios.patch('/orders/assigned', {
					oid: orderid,
					uid: userid,
					authToken: this.$store.state.sessionUnique
				// eslint-disable-next-line no-unused-vars
				}).then(function (response) {
					that.$toast.add({severity:'success', summary:'Confirmed', detail:'Order updated successfully.', life: 3000, function () {
						that.customer1 = response.data.orders
						that.loading1 = false
					}});
				});
			},
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
			formatDate(value) {
				return value.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			calculateCustomerTotal(name) {
				let total = 0;
				if (this.customer3) {
					for (let customer of this.customer3) {
						if (customer.representative.name === name) {
							total++;
						}
					}
				}

				return total;
			}
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}
</style>
